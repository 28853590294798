import { axiosApiInstanceCheckin } from "../utils/axiosClient";

const candidateApi = {
  getAllCandidates: () => {
    const path = `/api/candidate/getAllCandidate`;
    return axiosApiInstanceCheckin.get(path);
  },
  getCandidateEvaluated: ()=> {
    const path = `/api/candidate/getCandidateEvaluated`;
    return axiosApiInstanceCheckin.get(path);
  },
  getCandidateByQuery: (data)=>{
    const path = `/api/candidate/getAllCandidateByQuery`;
    return axiosApiInstanceCheckin.post(path, data);
  },
  getCandidate: (id) => {
    const path = `/api/candidate/getIDCandidate/${id}`;
    return axiosApiInstanceCheckin.get(path);
  },
  createCandidate: (data) => {
    const path = `/api/candidate/createCandidate`;
    return axiosApiInstanceCheckin.post(path, data);
  },
  updateCandidate: (data, id) => {
    const path = `/api/candidate/updateCandidate/${id}`;
    return axiosApiInstanceCheckin.patch(path, data);
  },
  deletCandidate: (id) => {
    const path = `/api/candidate/deleteCandidate/${id}`;
    return axiosApiInstanceCheckin.delete(path);
  },
  uploadFileCandidate: (data) => {
    const path = `/api/candidate/uploadFileCandidate`;
    return axiosApiInstanceCheckin.post(path, data);
  },

};

export default candidateApi;
