import React from "react";
import ReactDOM from "react-dom";
import Layout from "./layout/Layout";
import { RecoilRoot } from "recoil";
// import "./styles/index.css"
// import "antd/dist/antd.css";
import "./assets/styles/main.scss";
import { ConfigProvider } from "antd";
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ConfigProvider theme={{ hashed: false }}>
        <Layout />
      </ConfigProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
