import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import prototype from "../../assets/img/prototype.webp";
import video_bg from "../../assets/img/video-bg.jpg";
import i18n from "../../lib/Language";
import { Link } from "react-router-dom";

const Business = () => {
  return (
    <div className="stem_square_container product_item_container">
      <h3>{i18n.t("products.productBusiness")}</h3>
      <Row gutter={[30, 30]} align="middle">
        <Col xs={24} lg={8}>
          <Link to="/solutions/bigdata">
            <CardBasic
              title="YellowFin"
              imgSrc={prototype}
              desc={i18n.t("products.productYellowFinDesc")}
            />
          </Link>
        </Col>
        <Col xs={24} lg={8}>
          <Link to="/solutions/bigdata">
            <CardBasic
              title="BI"
              imgSrc={video_bg}
              desc={i18n.t("products.productBIDesc")}
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Business;
