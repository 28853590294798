import React, { useEffect } from "react";
import OurSolution from "../home/our_solution";
import OurSourcing from "./our_sourcing";

const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="box-style">
      <div className="container-wrapper">
        <OurSolution />
        <OurSourcing />
      </div>
    </div>
  );
};

export default Solutions;
