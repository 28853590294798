import { Col, Divider, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { useNews } from "../../../hooks/news";
import { useParams } from "react-router-dom";
import { useCareer } from "../../../hooks/career";
import parse from "html-react-parser";
import BannerButton from "../../../components/Global/Buttons/BannerButton";
import CareerRecentPost from "../../../components/Careers/CareerRecentPost";
import { useRecoilValue } from "recoil";
import { careersState } from "../../../recoil/atom/careerState";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CareerDetail = () => {
  const { isLoading, getCareerById, career, getAllCareers } = useCareer();
  const careers = useRecoilValue(careersState);

  useEffect(() => {
    if (careers?.length === 0) {
      getAllCareers();
    }
  }, []);

  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (+id) {
      getCareerById(+id);
    }
  }, [+id]);
  return (
    <>
      <div className="box-style">
        <div className="container career_detail_container">
          <h1 className="fz-40 color-red">tuyển dụng {career?.TITLE}</h1>
          <Row gutter={[30, 20]}>
            <Col xs={24} sm={24} lg={16}>
              {isLoading ? (
                <div className="empty-wrapper">
                  <LoadingOutlined style={{ fontSize: 30 }} />
                </div>
              ) : (
                <div className="">
                  <div className="career_detail_content">
                    <p
                      className="text-p career_editor"
                      style={{ marginBottom: 20 }}
                    >
                      {parse(String(career?.CONTENT))}
                    </p>
                  </div>
                  <a
                    href="http://hrm.novasquare.vn:3003/hrm/apply"
                    target="_blank"
                  >
                    <Link to="/apply">
                      <BannerButton title="Apply" />
                    </Link>
                  </a>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} lg={0}>
              <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <CareerRecentPost data={careers?.slice(0, 10)} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CareerDetail;
