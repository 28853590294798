import { Col, Image, Row } from "antd";
import React, { useEffect } from "react";
import check from "../../../assets/img/icons/check.svg";
import BI_solution from "../../../assets/img/BI_solution/BI_solution.webp";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import tool1 from "../../../assets/img/BI_solution/tool1.png";
import tool2 from "../../../assets/img/BI_solution/tool2.png";
import SwiperLogo from "../../Global/Swiper/SwiperLogo";
import i18n from "../../../lib/Language";

const arrTool = [tool1, tool2];

const BISolution = () => {
  const solutions = ["PowerBI", "YellowFin"];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="BISolution_container" style={{ marginTop: "40px" }}>
      <h1 style={{ marginBottom: 24 }} className="fz-40 color-red text-center">
        BI
      </h1>
      <div className="mt-30">
        <Row gutter={[20, 20]} align="start">
          <Col xs={24} sm={12}>
            <div className="flex-item-center h-100">
              <div>
                {solutions.map((item) => (
                  <div className="mt-16">
                    <Image src={check} alt="" preview={false} /> &ensp;
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div>
              <Image src={BI_solution} preview={false} />
            </div>
          </Col>
        </Row>
      </div>
      {/* tools */}
      <div className="mt-50 tools">
        <h2 className="mb-16">{i18n.t("solution.tool")}</h2>
        <SwiperLogo arrImage={arrTool} />
      </div>
    </div>
  );
};

export default BISolution;
