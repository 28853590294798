import React, { useEffect } from "react";
import { Col, Image, Row } from "antd";
import lms from "../../..//assets/img/erp/lms.webp";
import lms_model from "../../..//assets/img/erp/lms_model.png";

import check from "../../../assets/img/icons/check.svg";
import i18n from "../../../lib/Language";

export default function LMS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const solutions = [
    i18n.t("products.productLMSSolution_1"),
    i18n.t("products.productLMSSolution_2"),
    i18n.t("products.productLMSSolution_3"),
    i18n.t("products.productLMSSolution_4"),
    i18n.t("products.productLMSSolution_5"),
    i18n.t("products.productLMSSolution_6"),
  ];
  return (
    <div className="container-wrapper" style={{ marginTop: "150px" }}>
      <div className="container product_detail_container">
        <div className="flex-column-item-center">
          <h1 style={{ marginBottom: 24 }} className="fz-40 color-red">
            {i18n.t("products.productLMSTitle")}
          </h1>
          <b style={{ fontSize: 24 }}>(LMS)</b>
        </div>
        <div className="mt-50">
          <h2 className="mb-16">
            {i18n.t("products.productLMSTitleSolution")}
          </h2>
          <Row gutter={[20, 20]} style={{ marginBottom: "100px" }}>
            <Col xs={24} sm={10}>
              <div className="flex-item-center h-100">
                <div>
                  {solutions.map((item) => (
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} /> &ensp;
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={14}>
              <Image src={lms} preview={false} />
            </Col>
          </Row>
          <Image src={lms_model} />
        </div>
      </div>
    </div>
  );
}
