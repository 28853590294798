import React from "react";
import mail from "../../assets/img/mail.svg";
import phone from "../../assets/img/phone.svg";
import map_pin from "../../assets/img/map-pin.svg";
import { Col, Row, Image } from "antd";
import i18n from "../../lib/Language";

const ContactInfomation = () => {
  return (
    <>
      <div className="infomation_contact">
        <h4>{i18n.t("contact.contactInfomation")}</h4>
        <div className="info_menu">
          <Row align="middle">
            <Col span={2}>
              <Image src={mail} alt="" preview={false} />
            </Col>
            <Col span={22}>
              <p>info@novasquare.vn</p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={2}>
              <Image src={phone} alt="" preview={false} />
            </Col>
            <Col span={22}>
              <p>(+84) 913-111-576</p>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <Image src={map_pin} alt="" preview={false} />
            </Col>
            <Col span={22}>
              <div className="info_menu">
                <div className="address_info">
                  <p className="bold-500 mt-4 fz-20">VietNam</p>
                  <div className="line-height-24" style={{ display: "flex" }}>
                    <p className="head_office" style={{ marginRight: "4px" }}>
                      Head office:
                    </p>
                    <div>
                      <p>
                        47 Hai Ho Str, Hai Chau Dist, Da Nang City, VietNam.
                      </p>
                      <p>
                        35 Cao Thang Str, Hai Chau Dist, Da Nang City, VietNam.
                      </p>
                    </div>
                  </div>

                  <p className="line-height-24">
                    Branch office: 05 Dong Da Str, Tan Binh Dist, HCM City,
                    VietNam.
                  </p>
                </div>
                <div className="address_info">
                  <p className="bold-500 fz-20">Korea</p>
                  <p className="line-height-24">
                    Representative office: 3F, Front1, Mapo-daero 122, Mapo-gu,
                    Seoul, Korea.
                  </p>
                </div>
                {/* <div className="address_info">
                  <p className="bold-500 fz-20">USA</p>
                  <p className="line-height-24">
                    Representative office: 2309 Foxhaven Dr W, JacksonVille, FL,
                    USA.
                  </p>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactInfomation;
