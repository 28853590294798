import React from "react";
import moment from "moment";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import { formatDate, getLocalMoment } from "../../../common";
import { languageState } from "../../../recoil/atom/languageState";
import { useRecoilValue } from "recoil";
import "moment/locale/vi";
import "moment/locale/ko";
import "moment/locale/ja";

const CardNews1 = ({ data }) => {
  const language = useRecoilValue(languageState);
  moment.locale(getLocalMoment(language));
  return (
    <div className="card-news " style={{ height: "48%" }}>
      <Link to={`/news/${data?.id}`}>
        <Row gutter={[10, 20]}>
          <Col span={14}>
            {" "}
            <div className="card-img1">
              <Image src={data?.IMAGE} alt="" preview={false} />
            </div>
          </Col>
          <Col span={10}>
            <div className="card-content flex-column-between">
              <div style={{ color: "#727272", fontSize: 12, marginBottom: 10 }}>
                <span>
                  {moment(data?.CREATED_DATE).format(formatDate.TypeEng)}
                </span>
              </div>
              <div className="line-height-24" style={{ marginBottom: 16 }}>
                <h4 className="bold-500" style={{ marginBottom: 4 }}>
                  {data?.TITLE}
                </h4>
                <p className="news-desc1">{data?.DESC}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Link>
    </div>
  );
};

export default CardNews1;
