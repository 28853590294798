import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../lib/Language";
import NovaSquare_profile from "../../assets/filePdf/NovaSquare_profile.pdf";
import BannerButton from "../Global/Buttons/BannerButton";
import { DownloadOutlined } from "@ant-design/icons";

const Presentation = () => {
  return (
    <div className="presentation_container">
      <h1 className="fz-40 color-red">{i18n.t("aboutUs.PRESENTATION")}</h1>
      <div className="action_more">
        <NavLink to="/">
          <span>{i18n.t("aboutUs.companyProfile")}</span>
        </NavLink>
        <span style={{ margin: "0px 11px" }}>||</span>
        <a
          style={{ cursor: "pointer" }}
          href={NovaSquare_profile}
          download={true}
        >
          {i18n.t("aboutUs.companyDownload")}
          <BannerButton icon={<DownloadOutlined />} />
        </a>
      </div>
    </div>
  );
};

export default Presentation;
