import { Col, Row, Image } from "antd";
import React from "react";
import logo from "../../../assets/img/logo.png";
import mail from "../../../assets/img/mail.svg";
import phone from "../../../assets/img/phone.svg";
import map_pin from "../../../assets/img/map-pin.svg";
import i18n from "../../../lib/Language";
import { Link } from "react-router-dom";

const Footer = () => {
  let arrString = i18n.t("aboutUs.facts.factAboutDesc").split("\n");
  return (
    <div className="container-wrapper footer-bg mt-50">
      <div className="container footer-container">
        <Row gutter={[10, 30]}>
          <Col xs={24} sm={24} lg={11}>
            <Row>
              <Col span={24} className="footer-logo">
                <Image src={logo} alt="" preview={false} width={160} />
              </Col>
              <Col span={24}>
                {arrString.map((item) => (
                  <p className="line-height-24">{item}</p>
                ))}
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} lg={5} className="flex-jus-center ">
            <div className="footer-menu">
              <h4>{i18n.t("footer.footerCompany")}</h4>
              <Link to="/about_us">
                <p>{i18n.t("menu.lblAboutUs")}</p>
              </Link>
              <Link to="/solutions">
                <p>{i18n.t("menu.lblSolutions")}</p>
              </Link>
              <Link to="/products">
                <p>{i18n.t("menu.lblProducts")}</p>
              </Link>
              <Link to="/news">
                <p>{i18n.t("menu.lblNews")}</p>
              </Link>
              <Link to="/careers">
                <p>{i18n.t("menu.lblCareers")}</p>
              </Link>
              <Link to="/contacts">
                <p>{i18n.t("menu.lblContacts")}</p>
              </Link>
            </div>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <div className="footer-menu">
              <h4>{i18n.t("footer.footerInfomation")}</h4>
              <Row>
                <Col span={2}>
                  <Image src={mail} alt="" preview={false} />
                </Col>
                <Col span={22}>
                  <p className="bold-500 mt-4">info@novasquare.vn</p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  <Image src={phone} alt="" preview={false} />
                </Col>
                <Col span={22}>
                  <p className="bold-500 mt-4">(+84) 913-111-576</p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  <Image src={map_pin} alt="" preview={false} />
                </Col>
                <Col span={22}>
                  <div className="footer-menu">
                    <p className="bold-500 mt-4">VietNam</p>
                    <div className="footer-info">
                      <p className="line-height-24">
                        {i18n.t("footer.localHeadOffice")}: 47 Hai Ho Str, Hai
                        Chau Dist, Da Nang City, VietNam
                      </p>
                      <p className="line-height-24">
                        35 Cao Thang Str, Hai Chau Dist, Da Nang City, VietNam.
                      </p>
                      <p className="line-height-24">
                        {i18n.t("footer.localBranchOffice")}: 05 Dong Da Str,
                        Tan Binh Dist, HCM City, VietNam.
                      </p>
                    </div>
                    <div className="footer-info">
                      <p className="bold-500">Korea</p>
                      <p className="line-height-24">
                        {i18n.t("footer.localRepresentativeOffice")}: 3F,
                        Front1, Mapo-daero 122, Mapo-gu, Seoul, Korea.
                      </p>
                    </div>
                    {/* <div className="footer-info">
                      <p className="bold-500">USA</p>
                      <p className="line-height-24">
                        {i18n.t("footer.localRepresentativeOffice")}: 2309
                        Foxhaven Dr W, JacksonVille, FL, USA.
                      </p>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div className="footer-copyright">©Copyright 2024 – NovaSquare</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
