import {
  InboxOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import candidateApi from "../../api/candidateApi";
import logo from "../../assets/img/logo.png";
import "./style.css";
import { useHistory } from "react-router-dom";
import dataGenderHrm from "../../data_json/dataGenderHrm.json";

const { Dragger } = Upload;
const Apply = () => {
  const infoLocal = JSON.parse(localStorage.getItem("auth"))?.auth?.profile;
  const [form] = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  // delete attached files
  // HANDLE UPLOAD FILE
  const onChangeFile = async ({ file, fileList }) => {
    // CHECK DELETE FILE
    if (file.status === "removed") {
      setFileList(fileList);
    }
    if (!(file?.size / 1024 / 1024 < 20)) {
      return;
    }

    file.status = "done";
    file.name = file.name;
    setFileList(fileList);
  };

  const beforeUpload = (file) => {
    const isImg =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/heif" ||
      file.type === "image/*" ||
      file.type === "text/csv" ||
      file.type === "application/pdf" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type ===
        "application/vnd.openxmlformatsofficedocument.wordprocessingml.document";
    if (!isImg) {
      alert("You can only upload .pdf, .xls, .xlsx, .png and .jpg");
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      enqueueSnackbar(`Upload file thất bại`, {
        variant: "error",
      });
    }
    return isLt2M;
  };
  const props = {
    onChange: onChangeFile,
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    beforeUpload: beforeUpload,
    maxCount: 3,
    // onPreview: handlePreview,
    fileList: fileList && fileList,
    onDrop(e) {
      // console.log("Dropped files", e);
    },
  };

  const [captchaCode, setCaptchaCode] = useState();

  const generateCaptchaCode = () => {
    // declare all characters
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  // generate random strings
  useEffect(() => {
    const captchaCode = generateCaptchaCode();
    setCaptchaCode(captchaCode);
  }, []);

  const handleRefreshCaptcha = () => {
    const captchaCode = generateCaptchaCode();
    form.setFieldValue("CAPTCHA", "");
    setCaptchaCode(captchaCode);
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const { CAPTCHA, file, ...newData } = values;
        createCandidate(newData);
        handleRefreshCaptcha();
      })
      .catch((error) => {
        handleRefreshCaptcha();
        // console.log('error:', error);
      });
  };
  const createCandidate = async (data) => {
    setIsLoading(true);
    try {
      let res = await candidateApi.createCandidate(data);
      if (res.data) {
        setIsLoading(false);
        beforeCreate(res.data?.elements?.id);
      }
    } catch (error) {
      setIsLoading(false);

      if (
        error.response.data.status !== 401 ||
        error.response.data.status !== 400
      ) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }
    }
  };

  const beforeCreate = (id) => {
    var bar = new Promise((resolve, reject) => {
      fileList.forEach(async (item, index, array) => {
        const formData = new FormData();
        formData.append("CANDIDATE_ID", id);
        formData.append("uploadFile", item?.originFileObj);
        try {
          let res = await candidateApi.uploadFileCandidate(formData);
        } catch (error) {
          reject();
        }
        if (index === array.length - 1) {
          resolve();
        }
      });
    });
    bar.then(
      () => {
        success();
      },
      () => enqueueSnackbar("Nộp đơn thất bại!", { variant: "error" })
    );
  };
  const beforeSubmit = () => {
    form.resetFields();
    setFileList([]);
  };
  const success = () => {
    Modal.success({
      content: "Cảm ơn bạn đã nộp CV. Chúng tôi sẽ liên lạc với bạn sớm nhất!",
      onOk() {
        beforeSubmit();
      },
    });
  };
  return (
    <Row className="container-recuitment" justify="center">
      <Col span={24} className="background-out">
        <Row justify="center">
          <Col>
            <Image
              onClick={() => history.push("/")}
              src={logo}
              preview={false}
              width={150}
              style={{ marginBottom: 24 }}
            />
          </Col>
        </Row>
        <Form form={form} layout="vertical">
          <Row justify="center" gutter={10}>
            <Col xxl={4} lg={7} md={9} sm={14} xs={14}>
              <Form.Item
                name="FIRST_NAME"
                label="Họ lót"
                rules={[
                  {
                    required: true,
                    message: false,
                  },
                  {
                    validator: (_, value) =>
                      value.trim() === ""
                        ? Promise.reject(new Error("Vui lòng nhập họ lót!"))
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xxl={2} lg={3} md={5} sm={6} xs={7}>
              <Form.Item
                name="LAST_NAME"
                label="Tên"
                rules={[
                  {
                    required: true,
                    message: false,
                  },
                  {
                    validator: (_, value) =>
                      value.trim() === ""
                        ? Promise.reject(new Error("Vui lòng nhập tên!"))
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10} style={{ height: 85 }}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="GENDER_ID"
                label="Giới tính"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn giới tính!",
                  },
                ]}
              >
                <Select options={dataGenderHrm} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="PHONE"
                label="Số điện thoại"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập SĐT!",
                  },
                  {
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    message: "sđt không hợp lệ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="EMAIL"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Email!",
                  },
                  {
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "email không hợp lệ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="POSITION_NAME"
                label="Vị trí ứng tuyển"
                rules={[
                  {
                    required: true,
                    message: false,
                  },
                  {
                    validator: (_, value) =>
                      value.trim() === ""
                        ? Promise.reject(
                            new Error("vị trí ứng tuyển không hợp lệ")
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="DESC"
                label="Nội dung"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Nội dung!",
                  },
                ]}
              >
                <TextArea rows={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="file"
                label="File CV"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng gửi cv!",
                  },
                ]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click hoặc thả file vào đây</p>
                  <p className="ant-upload-hint">
                    Tối đa 3 file <br /> {"< 20 mb"}
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Form.Item
                name="CAPTCHA"
                label="Captcha"
                rules={[
                  { required: true, message: "Vui lòng nhập captcha" },
                  {
                    pattern: new RegExp(`^${captchaCode}$`, "g"),
                    message: "Captcha không khớp",
                  },
                ]}
              >
                <Row gutter={10}>
                  <Col span={16}>
                    <Input />
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <div className="captcha-box">{captchaCode}</div>
                    <Button
                      type="primary"
                      icon={<ReloadOutlined />}
                      title="Tại lại mã captcha"
                      onClick={handleRefreshCaptcha}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={10}>
            <Col xxl={6} lg={10} md={14} sm={20} xs={21}>
              <Row>
                <Col span={24}>
                  {" "}
                  <Button
                    style={{ width: "100%" }}
                    className="btn-submit-cv"
                    type="primary"
                    onClick={onOk}
                  >
                    {isLoading ? <LoadingOutlined /> : "NỘP CV"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default Apply;
