import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import i18n from "../../lib/Language";

const ContactForm = ({}) => {
  return (
    <div className="contact_form">
      <h4>{i18n.t("contact.contactUs")}</h4>
      <ul className="part_contact">
        <h5>{i18n.t("contact.bussinessDepartment")}</h5>
        <li>
          <span>Email: </span>
          <a href="mailto:sale@novasquare.vn">sale@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84971234042">(+84) 971.234.042</a>
        </li>
      </ul>
      <ul className="part_contact">
        <h5>{i18n.t("contact.onlineDepartment")}</h5>
        <li>
          <span>Email: </span>
          <a href="mailto:online@novasquare.vn">online@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84971234042">(+84) 971.234.043</a>
        </li>{" "}
        <li>
          <span>Zalo: </span>
          <a href="https://zalo.me/84971234043">(+84) 971.234.043</a>
        </li>
      </ul>
      <ul className="part_contact">
        <h5>{i18n.t("contact.careerDepartment")}</h5>
        <li>
          <span>Email: </span>
          <a href="mailto:careers@novasquare.vn">careers@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84913111576">(+84) 913.111.576</a>
        </li>
      </ul>
    </div>
  );
};

export default ContactForm;
