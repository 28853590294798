import React from "react";
import moment from "moment";
import { Image } from "antd";
import { Link } from "react-router-dom";
import { formatDate, getLocalMoment } from "../../../common";
import { useRecoilValue } from "recoil";
import { languageState } from "../../../recoil/atom/languageState";
import "moment/locale/vi";
import "moment/locale/ko";
import "moment/locale/ja";

const CardNewsHome = ({ data, height }) => {
  const language = useRecoilValue(languageState);
  moment.locale(getLocalMoment(language));
  return (
    <div className={`card-newshome  ${height}`}>
      <Link to={`/news/${data?.id}`}>
        <div className="card-img">
          <Image src={data?.IMAGE} alt="" preview={false} />
        </div>
        <div className="card-content">
          <div style={{ color: "#727272", fontSize: 12, marginBottom: 10 }}>
            <span>{moment(data?.CREATED_DATE).format(formatDate.TypeEng)}</span>
          </div>
          <div className="line-height-24" style={{ marginBottom: 14 }}>
            <h4 className="bold-500 news-title" style={{ marginBottom: 10 }}>
              {data?.TITLE}
            </h4>
            <p className="news-desc">{data?.DESC}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CardNewsHome;
