import { Col, Image, Row } from "antd";
import React, { useEffect } from "react";
import check from "../../../assets/img/icons/check.svg";
import bigdata_model from "../../../assets/img/bigdata_model.webp";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import tool1 from "../../../assets/img/tool_bigdata/tool1.png";
import tool2 from "../../../assets/img/tool_bigdata/tool2.webp";
import tool3 from "../../../assets/img/tool_bigdata/tool3.webp";
import tool4 from "../../../assets/img/tool_bigdata/tool4.webp";
import tool5 from "../../../assets/img/tool_bigdata/tool5.webp";
import tool6 from "../../../assets/img/tool_bigdata/tool6.webp";
import tool7 from "../../../assets/img/tool_bigdata/tool7.webp";
import tool8 from "../../../assets/img/tool_bigdata/tool8.webp";
import SwiperLogo from "../../Global/Swiper/SwiperLogo";
import i18n from "../../../lib/Language";

const arrTool = [tool1, tool2, tool3, tool4, tool5, tool6, tool7, tool8];

const BigDataSolution = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const solutions = [
    i18n.t("solution.bigDataSolution_1"),
    i18n.t("solution.bigDataSolution_2"),
    i18n.t("solution.bigDataSolution_3"),
    i18n.t("solution.bigDataSolution_4"),
    i18n.t("solution.bigDataSolution_5"),
    i18n.t("solution.bigDataSolution_6"),
    i18n.t("solution.bigDataSolution_7"),
    i18n.t("solution.bigDataSolution_8"),
    i18n.t("solution.bigDataSolution_9"),
  ];
  return (
    <div className="BigDataSolution_container">
      <h1 style={{ marginBottom: 24 }} className="fz-40 color-red text-center">
        BIG DATA
      </h1>
      <div className="mt-30">
        <h2 className="mb-16">BIG DATA</h2>
        <Row gutter={[20, 20]} align="start">
          <Col xs={24} sm={12}>
            <div className="flex-item-center h-100">
              <div>
                {solutions.map((item) => (
                  <div className="mt-16">
                    <Image src={check} alt="" preview={false} />
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div>
              <Image src={bigdata_model} />
            </div>
          </Col>
        </Row>
      </div>
      {/* tools */}
      <div className="mt-50 tools">
        <h2 className="mb-16">{i18n.t("solution.tool")}</h2>
        <SwiperLogo arrImage={arrTool} />
      </div>
    </div>
  );
};

export default BigDataSolution;
