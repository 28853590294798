import { Card } from "antd";
import React from "react";
const { Meta } = Card;

const CardBasic = ({ imgSrc, desc, title }) => {
  return (
    <>
      <Card
        hoverable
        style={{ width: "100%" }}
        cover={<img alt="example" src={imgSrc} />}
      >
        <Meta title={title} description={desc} />
      </Card>
    </>
  );
};

export default CardBasic;
