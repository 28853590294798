import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import NewRecentPost from "../../../components/News";
import { useNews } from "../../../hooks/news";
import moment from "moment";
import { formatDate, getLocalMoment } from "../../../common";
import parse from "html-react-parser";
import { LoadingOutlined } from "@ant-design/icons";
import i18n from "../../../lib/Language";
import { languageState } from "../../../recoil/atom/languageState";
import { useRecoilValue } from "recoil";
import "moment/locale/vi";
import "moment/locale/ko";
import "moment/locale/ja";

const NewDetail = () => {
  const language = useRecoilValue(languageState);
  moment.locale(getLocalMoment(language));

  const { isLoading, getAllNewss, newss, getNews, news } = useNews();
  useEffect(() => {
    if (newss?.length === 0) {
      getAllNewss();
    }
  }, []);

  const { id } = useParams();
  // GET NEWS BY ID
  useEffect(() => {
    window.scrollTo(0, 0);
    if (+id) {
      getNews(+id);
    }
  }, [+id]);

  return (
    <div className="box-style">
      <div className="container-wrapper">
        <div className="container">
          <div className="flex-column-item-center">
            <h1 style={{ marginBottom: 20 }} className="fz-40 color-red">
              {i18n.t("news.newTitle")}
            </h1>
            <p className="text-center"></p>
          </div>
        </div>

        <div className="container mt-30">
          <Row gutter={[30]}>
            <Col xs={24} sm={24} lg={16}>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingOutlined style={{ fontSize: 30 }} />
                </div>
              ) : (
                <div className="news-detail-wrapper">
                  <h1 className="new-detail-title">{news?.TITLE}</h1>
                  <div className="news-detail-info">
                    <span>By&nbsp;</span>
                    <span>
                      <strong>{news?.NEWS_BY}</strong>
                    </span>
                    <span className="detail-date">
                      {moment(news?.CREATED_DATE).format(formatDate.TypeEng)}
                    </span>
                  </div>
                  <div className="news-detail-content">
                    <p className="text-p">{news?.DESC}</p>
                    <p
                      className="text-p news-info-editor"
                      style={{ marginBottom: 20 }}
                    >
                      {parse(String(news?.CONTENT))}
                    </p>
                  </div>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} lg={0}>
              <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <NewRecentPost data={newss?.slice(0, 10)} loading={isLoading} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default NewDetail;
