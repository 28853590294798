import { Col, Row } from "antd";
import React from "react";
import CardCareer from "./CardCareer";

const ListCareer = ({ listCareer }) => {
  return (
    <div className="list_career_container">
      <Row gutter={[30, 30]} align="middle">
        {listCareer.map((career, index) => {
          return (
            <Col xs={24} lg={12} className="career_item" key={index}>
              <CardCareer
                nameCareer={career?.TITLE}
                addressCareer="Da Nang"
                imgSrc={career?.IMAGE}
                desc={career?.DESC}
                careerId={career?.id}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ListCareer;
