import React from "react";
import { Col, Row } from "antd";
import edtech from "../../../assets/img/icons/edtech.svg";
import iot from "../../../assets/img/icons/iot.svg";
import bigdata from "../../../assets/img/icons/bigdata.svg";
import blockchain from "../../../assets/img/icons/blockchain_icon.svg";
import SolutionItem from "../../../components/Home/OutSolution";
import i18n from "../../../lib/Language";
import { Link } from "react-router-dom";

const OurSolution = () => {
  return (
    <div className="mt-50">
      <div className="container-wrapper">
        <div className="container">
          <div className="flex-column-item-center">
            <h1 style={{ marginBottom: 24 }} className="fz-40 color-red">
              {i18n.t("home.solutions.solutionTitle")}
            </h1>
          </div>
        </div>
      </div>
      <div className="container-wrapper bg-default solution-wrapper">
        <div className="container">
          <Row gutter={[30, 20]}>
            <Col xs={24} sm={12} lg={6}>
              <a href="http://stemsquare.vn/" target="_blank">
                <SolutionItem
                  icon={edtech}
                  title="EDTECH"
                  content={i18n.t("home.solutions.solutionStemDesc")}
                />
              </a>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Link to="/solutions/iots">
                <SolutionItem
                  icon={iot}
                  title="IOT"
                  content={i18n.t("home.solutions.solutionIotDesc")}
                />
              </Link>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Link to="/solutions/blockchain">
                <SolutionItem
                  icon={blockchain}
                  title="BLOCKCHAIN"
                  content={i18n.t("home.solutions.solutionBlockchainDesc")}
                />
              </Link>
            </Col>

            <Col xs={24} sm={12} lg={6}>
              <Link to="/solutions/bigdata">
                <SolutionItem
                  icon={bigdata}
                  title="BIG DATA/ BI/ AI"
                  content={i18n.t("home.solutions.solutionBigDataDesc")}
                />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OurSolution;
