import { Col, Empty, Image, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const PortfolioTab = ({ data }) => {
  return (
    <div>
      <Row gutter={[20, 20]}>
        {data?.length ? (
          data?.slice(0, 6)?.map((item, index) => (
            <Col xs={24} sm={12} lg={8} key={index}>
              {item.TYPE === 2 ? (
                <a href={item.link} target="_blank">
                  <div className="card-product">
                    <div className="product-img">
                      <Image src={item?.IMAGE} alt="" preview={false} />
                    </div>
                    <div className="product-content">
                      <h4>{item.TITLE}</h4>
                      <p>{item?.DESC}</p>
                    </div>
                  </div>
                </a>
              ) : (
                <Link to={item.link}>
                  <div className="card-product">
                    <div className="product-img">
                      <Image src={item?.IMAGE} alt="" preview={false} />
                    </div>
                    <div className="product-content">
                      <h4>{item.TITLE}</h4>
                      <p>{item?.DESC}</p>
                    </div>
                  </div>
                </Link>
              )}
            </Col>
          ))
        ) : (
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty />
          </div>
        )}
      </Row>
    </div>
  );
};

export default PortfolioTab;
