import React from "react";
import Routes from "../routers/Routes";
// import { SnackbarProvider } from "notistack";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { i18nConfig } from "../lib/Language";
import { languageState } from "../recoil/atom/languageState";

const Layout = () => {
  const language = useRecoilValue(languageState);
  i18nConfig(language);
  const BASE_NAME = process.env?.REACT_APP_PRODUCTION_POSTFIX
    ? process.env?.REACT_APP_PRODUCTION_POSTFIX
    : "/";
  return (
    <Router basename={BASE_NAME}>
      <Route
        render={() => (
          <div className="main-wrapper">
            {/* <SnackbarProvider maxSnack={3}> */}
            <Routes />
            {/* </SnackbarProvider> */}
          </div>
        )}
      />
    </Router>
  );
};

export default Layout;
