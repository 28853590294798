import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../../lib/Language";

const HeaderNavBar = ({}) => {
  const renderNavigations = () => {
    return (
      <>
        <li className="nav__item">
          <NavLink className="nav__link" to="" exact={true}>
            <span>{i18n.t("menu.lblHome")}</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink className="nav__link" to="/about_us">
            <span>{i18n.t("menu.lblAboutUs")}</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink className="nav__link" to="/products">
            <span>{i18n.t("menu.lblProducts")}</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink className="nav__link" to="/solutions">
            <span>{i18n.t("menu.lblSolutions")}</span>
          </NavLink>
        </li>

        <li className="nav__item">
          <NavLink className="nav__link" to="/news">
            <span>{i18n.t("menu.lblNews")}</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink className="nav__link" to="/careers">
            <span>{i18n.t("menu.lblCareers")}</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink className="nav__link" to="/contacts" exact={true}>
            <span>{i18n.t("menu.lblContacts")}</span>
          </NavLink>
        </li>
      </>
    );
  };

  return (
    <>
      <ul className="header__nav__container" style={{ gap: 25 }}>
        {renderNavigations()}
      </ul>
    </>
  );
};

export default HeaderNavBar;
