import React, { useEffect } from "react";
import Overview from "../../components/Contact/Overview";
import { Col, Row } from "antd";
import ContactInfomation from "../../components/Contact/ContactInfomation";
import ContactForm from "../../components/Contact/ContactForm";
import i18n from "../../lib/Language";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="box-style">
      <div className="container-wrapper ">
        <div className="container contact_container">
          <h1 className="fz-40 color-red">{i18n.t("contact.contactTitle")}</h1>
          <Overview />
          <Row gutter={[60, 30]} style={{ marginBottom: "86px" }}>
            <Col xs={24} lg={12}>
              <ContactInfomation />
            </Col>
            <Col xs={24} lg={12}>
              <ContactForm />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Contact;
