import React from "react";
import ceoTable from "../../assets/img/ceoTable.jpg";
import image_mission from "../../assets/img/image_mission.webp";
import vision from "../../assets/img/icons/vision.png";
import mision from "../../assets/img/icons/mision.png";

import { Col, Image, Row } from "antd";
import i18n from "../../lib/Language";
const ContentAbout = () => {
  const arrMess = i18n.t("aboutUs.ceoMessage.ceoMessageDesc").split("\n");
  return (
    <div className="content_about">
      <div id="ceo_message">
        <Row gutter={[50, 30]} align="middle">
          <Col xs={24} lg={14}>
            <div className="content">
              <h3>{i18n.t("aboutUs.ceoMessage.ceoMessageTitle")}</h3>
              {arrMess && arrMess.map((item) => <p>{item}</p>)}
            </div>
          </Col>
          <Col xs={24} lg={10}>
            <Image src={ceoTable} alt="" id="img_ceo_mess" preview={false} />
          </Col>
        </Row>
      </div>

      <div id="mission_and_vision">
        <Row align="middle">
          <Col xs={24} lg={10}>
            <img src={image_mission} alt="" id="img_mission" />
          </Col>
          <Col xs={24} lg={14}>
            <div className="content">
              <h3>{i18n.t("aboutUs.misstionVision.misstionVisionTitle")}</h3>
              <div className="miss_vision_item">
                <section>
                  <img src={mision} alt="" />
                  <span>{i18n.t("aboutUs.misstionVision.missionTitle")}</span>
                </section>
                <p>{i18n.t("aboutUs.misstionVision.missionDesc")}</p>
              </div>

              <div className="miss_vision_item">
                <section>
                  <img src={vision} alt="" />
                  <span>{i18n.t("aboutUs.misstionVision.Vision")}</span>
                </section>
                <p>{i18n.t("aboutUs.misstionVision.visionDesc")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContentAbout;
