import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import i18n from "../../../lib/Language";
import React, { useEffect } from "react";
import { useNews } from "../../../hooks/news";
import { ArrowRightOutlined } from "@ant-design/icons";
import CardNews from "../../../components/Global/Cards/CardNews";
import CardNews1 from "../../../components/Global/Cards/CardNews1";
import CardNewsHome from "../../../components/Global/Cards/CardNewsHome";
import BannerButton from "../../../components/Global/Buttons/BannerButton";

const NewsReleases = () => {
  const { newss, getAllByQuery } = useNews();
  useEffect(() => {
    if (newss?.length === 0) {
      getAllByQuery();
    }
  }, []);

  return (
    <div className="container-wrapper mt-50">
      <div className="container">
        <div className="flex-column-item-center">
          <h1 style={{ marginBottom: 50 }} className="fz-40 color-red">
            {i18n.t("home.releases.releasesTitle")}
          </h1>
        </div>
        <Row gutter={[20, 20]}>
          <Col xs={0} sm={0} lg={12}>
            <CardNewsHome data={newss?.[0]} height="h-600" />
          </Col>
          <Col xs={0} sm={0} lg={12}>
            <div className="flex-column-between h-600">
              <CardNews1 data={newss?.[1]} />
              <CardNews1 data={newss?.[2]} />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={0}>
            <CardNews data={newss?.[0]} />
          </Col>
          <Col xs={24} sm={24} lg={0}>
            <div className="flex-column-between" style={{ gap: 20 }}>
              <CardNews data={newss?.[1]} />
              <CardNews data={newss?.[2]} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <div className="flex-center">
              <Link to="/news">
                <BannerButton
                  title={i18n.t("home.releases.releasesBtnViewAll")}
                  icon={<ArrowRightOutlined />}
                />
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsReleases;
