import React from "react";
import moment from "moment";
import i18n from "../../lib/Language";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import { formatDate } from "../../common";
import { LoadingOutlined } from "@ant-design/icons";

const NewRecentPost = ({ data, loading }) => {
  return (
    <div className="news-wrapper">
      <div className="news-header">
        <p>{i18n.t("news.recentPost")}</p>
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingOutlined style={{ fontSize: 30 }} />
        </div>
      ) : (
        <>
          {data?.map((item, index) => (
            <div className="news-item" key={index}>
              <Link to={`/news/${item?.id}`}>
                <Row gutter={[10]}>
                  <Col span={8}>
                    <Image
                      src={item?.IMAGE}
                      alt=""
                      preview={false}
                      height={80}
                      width="100%"
                      style={{ borderRadius: 8 }}
                    />
                  </Col>
                  <Col
                    span={16}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 className="news-title1">{item?.TITLE}</h5>
                    <p className="news-desc2">{item?.DESC}</p>
                    <span style={{ color: "#727272", fontSize: 12 }}>
                      {moment(item?.CREATED_DATE).format(formatDate.TypeEng)}
                    </span>
                  </Col>
                </Row>
              </Link>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default NewRecentPost;
