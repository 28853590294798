import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Pagination,
  EffectFade,
  Parallax,
  Navigation,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import i18n from "../../../lib/Language";
import { Link } from "react-router-dom";
import BannerButton from "../../Global/Buttons/BannerButton";

const BannerPc = () => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, EffectFade, Parallax, Navigation]}
      pagination={{
        clickable: true,
      }}
      effect={"fade"}
      grabCursor={true}
      className="slider-banner mySwiper"
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      speed={600}
      parallax={true}
      navigation={true}
    >
      <SwiperSlide>
        <div className="slider-item">
          <div className="slider-1">
            <div class="slider-caption h-100">
              <div class="container flex-item-end top h-50">
                <div className="gap-10 baner-content">
                  <h1 className="text-center banner-animaiton ">
                    {i18n.t("home.panel.panelNova.panelNovaTitle")}
                  </h1>

                  <p className="mt-16 text-center banner-animaiton ">
                    {i18n.t("home.panel.panelNova.panelNovaDesc")}
                  </p>
                  <div
                    style={{ marginTop: 30, width: "100%" }}
                    className="flex-center  banner-animaiton"
                  >
                    <Link to="/solutions/blockchain">
                      <BannerButton title={i18n.t("home.panel.panelBtn")} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-item">
          <div className="slider-2">
            <div class="slider-caption h-100">
              <div class="container flex-item-end h-50">
                <div className="gap-10 baner-content">
                  <h1 className="text-center banner-animaiton ">
                    {i18n.t("home.panel.panelTwell.panelTwellTitle")}
                  </h1>

                  <p className="mt-16 text-center banner-animaiton ">
                    {i18n.t("home.panel.panelTwell.panelTwellDesc")}
                  </p>
                  <div
                    style={{ marginTop: 30, width: "100%" }}
                    className="flex-center  banner-animaiton"
                  >
                    <Link to="/solutions/twells">
                      <BannerButton title={i18n.t("home.panel.panelBtn")} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-item">
          <div className="slider-3">
            <div class="slider-caption h-100">
              <div class="container flex-item-end h-50">
                <div className="gap-10 baner-content">
                  <h1 className="text-center banner-animaiton ">
                    {i18n.t("home.panel.panelStem.panelStemTitle")}
                  </h1>

                  <p className="mt-16 text-center banner-animaiton ">
                    {i18n.t("home.panel.panelStem.panelStemDesc")}
                  </p>
                  <div
                    style={{ marginTop: 30, width: "100%" }}
                    className="flex-center  banner-animaiton"
                  >
                    <a href="http://stemsquare.vn/" target="_blank">
                      <BannerButton title={i18n.t("home.panel.panelBtn")} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default BannerPc;
