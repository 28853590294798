import { Drawer, Image } from "antd";
import React, { useState } from "react";
import i18n from "../../../lib/Language";
import HeaderNavBar from "./HeaderNavBar";
import ChangeLanguage from "./ChangeLanguage";
import logo from "../../../assets/img/logo.png";
import { NavLink, Link } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="container-wrapper header">
        <div className="container header__container">
          {/* PC */}
          <div className="layout-pc w-100 flex-between">
            <div>
              <Link to="/">
                <Image
                  src={logo}
                  alt=""
                  preview={false}
                  style={{ width: 100 }}
                />
              </Link>
            </div>
            <div>
              <HeaderNavBar />
            </div>
            <div>
              <ChangeLanguage />
            </div>
          </div>

          {/* mobile */}
          <div className="w-100 layout-mobile flex-between">
            <div>
              <Link to="/">
                <Image
                  src={logo}
                  alt=""
                  preview={false}
                  style={{ width: 100 }}
                />
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <ChangeLanguage />
              </div>
              <div className="icon-menu" onClick={() => setOpen(!open)}>
                {open ? <CloseOutlined /> : <MenuOutlined />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        placement="top"
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="item-drawer" onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="" exact={true}>
              <span>{i18n.t("menu.lblHome")}</span>
            </NavLink>
          </p>
          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/about_us">
              <span>{i18n.t("menu.lblAboutUs")}</span>
            </NavLink>
          </p>
          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/products">
              <span>{i18n.t("menu.lblProducts")}</span>
            </NavLink>
          </p>
          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/solutions">
              <span>{i18n.t("menu.lblSolutions")}</span>
            </NavLink>
          </p>

          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/news">
              <span>{i18n.t("menu.lblNews")}</span>
            </NavLink>
          </p>
          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/careers">
              <span>{i18n.t("menu.lblCareers")}</span>
            </NavLink>
          </p>
          <p className="item-drawer " onClick={() => setOpen(false)}>
            <NavLink className="nav-link" to="/contacts" exact={true}>
              <span>{i18n.t("menu.lblContacts")}</span>
            </NavLink>
          </p>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
