import { Col, Image, Row } from "antd";
import React, { useEffect } from "react";
import i18n from "../../../lib/Language";
import check from "../../../assets/img/icons/check.svg";
import twell from "../../../assets/img/twell.svg";
import Other from "../../../assets/img/Other.svg";
import Device from "../../../assets/img/Device.svg";
import Garden from "../../../assets/img/Garden.svg";
import Security from "../../../assets/img/Security.svg";
import ProductItem from "../../../components/Blockchain";

const Iots = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="box-style">
      <div className="container-wrapper ">
        <div className="container">
          <div className="flex-column-item-center">
            <h1 style={{ marginBottom: 24 }} className="fz-40 color-red">
              IOT
            </h1>
            <p> {i18n.t("home.panel.panelTwell.panelTwellDesc")}</p>
          </div>
          <div className="mt-50">
            <h2 className="mb-16">
              {i18n.t("home.panel.panelTwell.panelTwellTitle")}
            </h2>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12}>
                <div className="flex-item-center h-100">
                  <div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />
                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_1")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_2")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_3")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_4")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_5")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_6")}
                      </span>
                    </div>
                    <div className="mt-16">
                      <Image src={check} alt="" preview={false} />

                      <span className="fz-16">
                        {i18n.t("home.panel.panelTwell.twell_7")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="twell-image">
                  <Image src={twell} preview={false} />
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-50 ">
            <h2 className="mb-30">
              {i18n.t("home.panel.panelTwell.twellProduct")}
            </h2>
            <Row gutter={[20, 40]}>
              <Col xs={24} sm={12} lg={6}>
                {" "}
                <ProductItem
                  content={i18n.t("home.panel.panelTwell.twellProduct_1")}
                  img={Device}
                />
              </Col>
              <Col xs={24} sm={12} lg={6} className="mt-50-twell">
                {" "}
                <ProductItem
                  content={i18n.t("home.panel.panelTwell.twellProduct_2")}
                  img={Garden}
                />
              </Col>

              <Col xs={24} sm={12} lg={6}>
                {" "}
                <ProductItem
                  content={i18n.t("home.panel.panelTwell.twellProduct_3")}
                  img={Security}
                />
              </Col>
              <Col xs={24} sm={12} lg={6} className="mt-50-twell">
                <ProductItem
                  content={i18n.t("home.panel.panelTwell.twellProduct_4")}
                  img={Other}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iots;
