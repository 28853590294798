import { Col, Image, Row } from "antd";
import React from "react";

const OutSourcingTab = ({ image }) => {
  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Image src={image} alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default OutSourcingTab;
