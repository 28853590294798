import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Image } from "antd";
export default function SwiperLogo({ arrImage }) {
  const slidesPerViewNumber = arrImage?.length <= 2 ? 2 : 3;
  return (
    <div className="swiper_logo">
      <Swiper
        grabCursor={true}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: slidesPerViewNumber,
            spaceBetween: 20,
          },
        }}
      >
        {arrImage.map((imgSrc) => (
          <SwiperSlide>
            <Image src={imgSrc} alt="" preview={false} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
