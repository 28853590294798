import React, { useEffect } from "react";
import StemSquare from "../../components/Products/StemSquare";
import Blockchain from "../../components/Products/Blockchain";
import i18n from "../../lib/Language";
import ERPProduct from "../../components/Products/ERPProduct";
import Business from "../../components/Products/Business";
import SmartApp from "../../components/Products/SmartApp";
import HealthApp from "../../components/Products/HealthApp";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="box-style">
        <div className="container-wrapper ">
          <div className="container product_container">
            <div className="product_header">
              <h1 className="fz-40 color-red">
                {i18n.t("products.productTitle")}
              </h1>
              <p>{i18n.t("products.productDesc")}</p>
            </div>
            <ERPProduct />
            <HealthApp />
            <Blockchain />
            <Business />
            <SmartApp />
            <StemSquare />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
