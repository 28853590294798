import React, { useState } from "react";
import { Col, Row } from "antd";
import angle_up_white from "../../assets/img/icons/angle_up_white.svg";
import angle_down_white from "../../assets/img/icons/angle_down_white.svg";
import img_integrate_impact from "../../assets/img/integrate_impact.png";
import img_service from "../../assets/img/img_service.png";
import img_unseen from "../../assets/img/img_unseen.png";
import img_access from "../../assets/img/img_access.png";
import i18n from "../../lib/Language";
const WhyUs = () => {
  const arrValue = [
    {
      title: `${i18n.t("aboutUs.whyUs.integrationTitle")}`,
      content: `${i18n.t("aboutUs.whyUs.integrationDesc")}`,
      desc: {
        img: img_integrate_impact,
        title: `${i18n.t("aboutUs.whyUs.integrationWhatWeDo")}`,
        content: `${i18n.t("aboutUs.whyUs.integrationDoDesc")}`,
      },
    },
    {
      title: `${i18n.t("aboutUs.whyUs.serviceTitle")}`,
      content: `${i18n.t("aboutUs.whyUs.serviceDesc")}`,
      desc: {
        img: img_service,
        title: `${i18n.t("aboutUs.whyUs.serviceNovaSquareClientExperience")}`,
        content: `${i18n.t(
          "aboutUs.whyUs.serviceNovaSquareClientExperienceDesc"
        )}`,
      },
    },
    {
      title: `${i18n.t("aboutUs.whyUs.findingTitle")}`,
      content: `${i18n.t("aboutUs.whyUs.finddingDesc")}`,
      desc: {
        img: img_unseen,
        title: `${i18n.t("aboutUs.whyUs.finddingOurInsights")}`,
        content: `${i18n.t("aboutUs.whyUs.finddingOurInsightsDesc")}`,
      },
    },
    {
      title: `${i18n.t("aboutUs.whyUs.accompanyingTitle")}`,
      content: `${i18n.t("aboutUs.whyUs.accompanyingDesc")}`,
      desc: {
        img: img_access,
        title: `${i18n.t("aboutUs.whyUs.accompanyR&DValue")}`,
        content: `${i18n.t("aboutUs.whyUs.accompanyR&DValueDesc")}`,
      },
    },
  ];
  const [indexSelect, setIndexSelect] = useState(0);

  return (
    <div className="why_us">
      <h1 className="fz-40 color-red">{i18n.t("aboutUs.whyUs.whyUsTitle")}</h1>
      <p style={{ margin: " 30px 0px", textAlign: "center" }}>
        {i18n.t("aboutUs.whyUs.whyUsDesc")}
      </p>

      <Row gutter={[30, 0]}>
        {arrValue.map((item, index) => {
          const checkIndexSelect = index === indexSelect;
          const infoReference = item.desc;
          return (
            <>
              <Col xs={24} lg={6} key={index}>
                <section className="info_us_container">
                  <div>
                    <p
                      className={`info_title ${
                        checkIndexSelect ? "active" : ""
                      }`}
                    >
                      {item.title}
                    </p>
                    <p className="info_content">{item.content}</p>
                  </div>

                  <span
                    className={`icon_arrow ${checkIndexSelect ? "active" : ""}`}
                    onClick={() => {
                      setIndexSelect(index);
                    }}
                  >
                    {checkIndexSelect ? (
                      <img src={angle_up_white} alt="" />
                    ) : (
                      <img
                        src={angle_down_white}
                        alt=""
                        className="angle_down"
                      />
                    )}
                  </span>
                </section>
              </Col>
              {index === indexSelect && (
                <div
                  className="container info_reference mobile"
                  style={{ marginTop: 0 }}
                >
                  <img src={infoReference.img} alt="" />
                  <section>
                    <p>{infoReference.title}</p>
                    <p>{infoReference.content}</p>
                  </section>
                </div>
              )}
            </>
          );
        })}
      </Row>

      <div className="PC">
        <div className="info_reference ">
          <img src={arrValue[indexSelect].desc.img} alt="" />
          <section>
            <p>{arrValue[indexSelect].desc.title}</p>
            {arrValue[indexSelect].desc.content.split("\n").map((item) => (
              <p>{item}</p>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
