import { Input } from "antd";
import React, { useEffect, useState } from "react";
import BannerButton from "../Global/Buttons/BannerButton";
import { SearchOutlined } from "@ant-design/icons";
import i18n from "../../lib/Language";

const SearchCareer = ({ searchCareer, setQuery }) => {
  const [valueInput, setValueInput] = useState();

  useEffect(() => {
    if (!valueInput) setQuery("");
  }, [valueInput]);

  const handleSearch = () => {
    searchCareer(valueInput);
  };

  return (
    <div className="search_career flex-center ">
      <div className="container flex-between">
        <Input
          placeholder={i18n.t("careers.careersPlaceholder")}
          className="input_search"
          onChange={(e) => {
            setValueInput(e.target.value);
          }}
          onPressEnter={handleSearch}
        />
        <div className="PC">
          <BannerButton
            onClick={handleSearch}
            title={i18n.t("careers.careerBtnSearch")}
          />
        </div>
        <div className="mobile">
          <BannerButton icon={<SearchOutlined />} onClick={handleSearch} />
        </div>
      </div>
    </div>
  );
};

export default SearchCareer;
