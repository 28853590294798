import { Image } from "antd";
import React from "react";

const SolutionItem = ({ icon, title, content }) => {
  return (
    <div className="card-solution">
      <div className="card-head">
        <Image src={icon} preview={false} />
        <h4 className="fz-20 bold-500 text-center">{title}</h4>
      </div>
      <p className="text-center ">{content}</p>
    </div>
  );
};

export default SolutionItem;
