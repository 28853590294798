import { useState } from "react";
import { useRecoilState } from "recoil";
import newsApi from "../api/newsApi";
import { careersState } from "../recoil/atom/careerState";
import moment from "moment/moment";

export const useCareer = () => {
  const [career, setCareer] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [listCareer, setListCareer] = useRecoilState(careersState);

  const getAllCareersNotExpired = (data) => {
    const today = moment();
    return data.filter((career) => {
      const expiredDate = moment(career?.EXPIRED_DATE);
      const checkNoExpired = today <= expiredDate;
      return checkNoExpired;
    });
  };

  const getAllCareers = async () => {
    setIsLoading(true);
    try {
      let res = await newsApi.getAllByQuery({ TYPE_ID: 2 });
      if (res?.data) {
        const careerNoExpired = getAllCareersNotExpired(res?.data?.elements);
        // setListCareer(careerNoExpired.sort((a, b) => b.id - a.id));
        setListCareer(res?.data?.elements.sort((a, b) => b.id - a.id));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCareerById = async (id) => {
    setIsLoading(true);
    try {
      let res = await newsApi.getNews(id);
      if (res.data) {
        setCareer(res.data.elements.news);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    getCareerById,
    getAllCareers,
    isLoading,
    career,
  };
};
