import { Button } from "antd";
import React, { useState } from "react";
import i18n from "../../../lib/Language";
import OutSourcingTab from "../../../components/Solution";
import our_process from "../../../assets/img/our_process.svg";
import ourtechnical from "../../../assets/img/ourtechnical.svg";

const OurSourcing = () => {
  const buttons = [
    {
      key: 1,
      type: 1,
      label: `${i18n.t("outSourcing.outSourcingBtnProcessing")}`,
    },
    {
      key: 2,
      type: 2,
      label: `${i18n.t("outSourcing.outSourcingBtnOurTechnical")}`,
    },
  ];
  const [key, setKey] = useState(buttons[0]);

  return (
    <div className="container mt-50">
      <div className="portfilio-containner flex-column-item-center">
        <h1 style={{ marginBottom: 20 }} className="fz-40 color-red">
          {i18n.t("outSourcing.outSourcingTitle")}
        </h1>
      </div>
      <div className="flex-center button-group-wrapper">
        <div className="button-group1">
          {buttons?.map((item, index) => (
            <Button
              className={`btn btn-tabs-item ${
                item.key === key.key && "btn-active"
              }`}
              key={index}
              onClick={() => setKey(item)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      </div>
      <div className="btn-tabs" style={{ marginTop: 30, marginBottom: 30 }}>
        <OutSourcingTab image={key?.key === 1 ? our_process : ourtechnical} />
      </div>
    </div>
  );
};

export default OurSourcing;
