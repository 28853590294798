import i18n from "../../lib/Language";
import { useNews } from "../../hooks/news";
import { Col, Row, Pagination, Divider } from "antd";
import NewRecentPost from "../../components/News";
import { onChangePagination } from "../../common";
import { LoadingOutlined } from "@ant-design/icons";
import NewsInfo from "../../components/News/NewsInfo";
import React, { useEffect, useMemo, useState } from "react";
import CardNews from "../../components/Global/Cards/CardNews";

const News = () => {
  const { isLoading, newss, getAllByQuery, getAllNewss } = useNews();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (newss?.length === 0) {
      getAllNewss();
    }
  }, []);

  const [pagination, setPagination] = useState({
    from: 0,
    to: 6,
    page: 1,
    sizepage: 6,
  });

  const newList = useMemo(() => {
    return newss?.filter((item) => item.id !== newss?.[0]?.id);
  }, [newss]);
  // set data for pagination
  const newData = useMemo(() => {
    return newList?.slice(pagination?.from, pagination?.to);
  }, [newList, pagination]);

  return (
    <div className="box-style">
      <div className="container-wrapper">
        <div className="container">
          <div className="flex-column-item-center">
            <h1 style={{ marginBottom: 20 }} className="fz-40 color-red">
              {i18n.t("news.newTitle")}
            </h1>
          </div>
        </div>
        <div className="container mt-30">
          <Row gutter={[30, 20]}>
            <Col xs={24} sm={24} lg={16}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  {isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: 500,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LoadingOutlined style={{ fontSize: 30 }} />
                    </div>
                  ) : (
                    <CardNews data={newss?.[0]} />
                  )}
                </Col>
                {newData?.slice(0, 6)?.map((item, index) => (
                  <Col xs={0} sm={0} lg={12} key={index}>
                    <NewsInfo data={item} />
                  </Col>
                ))}
                {newData?.slice(0, 6)?.map((item, index) => (
                  <Col xs={24} sm={24} lg={0} key={index}>
                    <CardNews data={item} />
                  </Col>
                ))}
                <Col span={24}>
                  <div className="flex-center pagination-wrapper">
                    <Pagination
                      defaultCurrent={1}
                      total={newss?.length}
                      defaultPageSize={8}
                      onChange={(pageNumber) =>
                        onChangePagination(
                          pageNumber,
                          pagination,
                          setPagination
                        )
                      }
                      current={pagination?.page}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} lg={0}>
              <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <NewRecentPost data={newss?.slice(0, 10)} loading={isLoading} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default News;
