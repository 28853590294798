import React from "react";
import { Image } from "antd";
import BK_JF_2022 from "../../assets/img/certificates/BK_JF_2022.webp";
import BK_JF_2023 from "../../assets/img/certificates/BK_JF_2023.webp";
import BSMART_2021 from "../../assets/img/certificates/BSMART_2021.webp";
import DNT_2022 from "../../assets/img/certificates/DNT_2022.webp";
import TQT_2023 from "../../assets/img/certificates/TQT_2023.webp";
import VTA_2018 from "../../assets/img/certificates/VTA_2018.webp";
import VTA_Duy_2022 from "../../assets/img/certificates/VTA_Duy_2022.webp";
import VTA_Duy_2024 from "../../assets/img/certificates/VTA_Duy_2024.webp";
import VTA2_2022 from "../../assets/img/certificates/VTA2_2022.webp";

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import i18n from "../../lib/Language";

const certificatesImage = [
  BK_JF_2022,
  BK_JF_2023,
  BSMART_2021,
  DNT_2022,
  TQT_2023,
  VTA_2018,
  VTA_Duy_2022,
  VTA_Duy_2024,
  VTA2_2022,
];
const Award = () => {
  return (
    <div className="award_container">
      <h1 className="fz-40 color-red">
        {i18n.t("aboutUs.CERTIFICATES&AWARD")}
      </h1>
      <Swiper
        grabCursor={true}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        slidesPerView={1}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {certificatesImage.map((certificate) => (
          <SwiperSlide>
            <Image src={certificate} alt="" width={200} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Award;
