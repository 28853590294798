import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import smart_home from "../../assets/img/smart_home.png";
import smart_garden from "../../assets/img/smart_garden.webp";
import AI_face from "../../assets/img/AI_face.webp";
import { Link } from "react-router-dom";
import i18n from "../../lib/Language";

const SmartApp = () => {
  return (
    <div className="smart_home_container product_item_container">
      <h3>{i18n.t("products.productSmartApp")}</h3>
      <Row gutter={[30, 30]} align="middle">
        <Col xs={24} lg={8}>
          <Link to="/solutions/twells">
            <CardBasic
              title={i18n.t("products.productSmartHomeTitle")}
              imgSrc={smart_home}
              desc={i18n.t("products.productSmartHomeDesc")}
            />
          </Link>
        </Col>
        <Col xs={24} lg={8}>
          <Link to="/solutions/twells">
            <CardBasic
              title={i18n.t("products.productSmarGardenTitle")}
              imgSrc={smart_garden}
              desc={i18n.t("products.productSmarGardenDesc")}
            />
          </Link>
        </Col>
        <Col xs={24} lg={8}>
          <Link to="/solutions/bigdata">
            <CardBasic
              title={i18n.t("products.productFaceTitle")}
              imgSrc={AI_face}
              desc={i18n.t("products.productFaceDesc")}
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default SmartApp;
