import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import health_app from "../../assets/img/health_app.jpg";
import i18n from "../../lib/Language";

const HealthApp = () => {
  return (
    <div className="stem_square_container product_item_container">
      <h3>{i18n.t("products.productHealth")}</h3>
      <Row gutter={[30, 30]} align="middle">
        <Col xs={24} lg={8}>
          {/* <a href="http://HealthApp.vn/" target="_blank"> */}
          <CardBasic
            title="EMR, HIS, LIS, PACS"
            imgSrc={health_app}
            desc={i18n.t("products.productHealthDesc")}
          />
          {/* </a> */}
        </Col>
      </Row>
    </div>
  );
};

export default HealthApp;
