import React from "react";
import { Col, Image, Row } from "antd";
import ajinomoto from "../../../assets/img/partner/ajinomoto.svg";
import beetech from "../../../assets/img/partner/beetech.svg";
import chiba from "../../../assets/img/partner/chiba.svg";
import gumi from "../../../assets/img/partner/gumi.svg";
import hcdc from "../../../assets/img/partner/hcdc.svg";
import lego from "../../../assets/img/partner/lego.svg";
import toppa from "../../../assets/img/partner/toppa.svg";
import via from "../../../assets/img/partner/via.svg";
import i18n from "../../../lib/Language";
import SwiperLogo from "../../../components/Global/Swiper/SwiperLogo";

const arrLogo = [ajinomoto, beetech, chiba, gumi, hcdc, lego, toppa, via];

const OurPartners = () => {
  return (
    <div className="container-wrapper mt-50 ">
      <div className="container">
        <div className="flex-column-item-center">
          <h1 className="fz-40 color-red">
            {i18n.t("home.partners.partnersTitle")}
          </h1>
        </div>
        <Row gutter={[30, 20]} style={{ marginTop: 20 }}>
          <Col span={24}>
            {/* <h2 style={{ marginBottom: 14, textAlign: "center" }}>
              {i18n.t("home.partners.partnersTitle")}
            </h2> */}
            <p className="line-height-24 text-center">
              {i18n.t("home.partners.partnersDesc")}
            </p>
          </Col>
          <Col span={24}>
            <SwiperLogo arrImage={arrLogo} />
            {/* <Row>
              <Col xs={12} sm={12} lg={6}>
                <Image src={ajinomoto} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                {" "}
                <Image src={chiba} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                {" "}
                <Image src={beetech} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <Image src={lego} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <Image src={gumi} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <Image src={hcdc} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <Image src={toppa} alt="" preview={false} />
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <Image src={via} alt="" preview={false} />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OurPartners;
