import { Col, Row } from "antd";
import React from "react";
import i18n from "../../lib/Language";

const Facts = () => {
  let arrString = i18n.t("aboutUs.facts.factAboutDesc").split("\n");
  return (
    <div className="facts">
      <h1 className="fz-40 color-red">{i18n.t("aboutUs.facts.factTitle")}</h1>
      <div className="bg_map">
        <Row align="middle">
          <Col xs={24} lg={13}>
            <section className="content_text">
              <h3>{i18n.t("aboutUs.facts.factAbout")}</h3>
              {arrString.map((item) => (
                <p>{item}</p>
              ))}
            </section>
          </Col>

          <Col xs={24} lg={11}>
            <section className="box_circle_container">
              <section className="box_circle">
                <p className="item">32+</p>
                <p>{i18n.t("aboutUs.facts.factProject")}</p>
              </section>
              <section className="box_circle">
                <p className="item">12+</p>
                <p>{i18n.t("aboutUs.facts.factProduct")}</p>
              </section>
              <section className="box_circle">
                <p className="item">30+</p>
                <p>{i18n.t("aboutUs.facts.factEmployee")}</p>
              </section>
            </section>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Facts;
