import React, { useEffect, useMemo, useState } from "react";
import ListCareer from "../../components/Careers/ListCareer";
import SearchCareer from "../../components/Careers/SearchCareer";
import { searchBasic } from "../../common";
import { useCareer } from "../../hooks/career";
import { useRecoilValue } from "recoil";
import { careersState } from "../../recoil/atom/careerState";
import { Col, Row, Spin } from "antd";
import CareerRecentPost from "../../components/Careers/CareerRecentPost";
import i18n from "../../lib/Language";
import { LoadingOutlined } from "@ant-design/icons";
const Careers = () => {
  const { getAllCareers, isLoading } = useCareer();
  const [query, setQuery] = useState();
  const [filteredCareersBySearch, setFilteredCareersBySearch] = useState([]);
  const careers = useRecoilValue(careersState);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (careers?.length === 0) {
      getAllCareers();
    }
  }, []);

  const dataCareers = useMemo(() => {
    return query ? filteredCareersBySearch : careers;
  }, [query, filteredCareersBySearch, careers]);

  const searchCareer = (queryString) => {
    setQuery(queryString);
    if (careers.length === 0 || !queryString) return;
    const arrSearchCareer = careers.filter((career) => {
      const careerCheck = {
        NAME: career?.TITLE,
        DESC: career?.DESC,
      };
      const checkSearch = searchBasic(careerCheck, queryString);
      return checkSearch;
    });
    setFilteredCareersBySearch(arrSearchCareer);
  };

  return (
    <div className="box-style">
      <div className="container-wrapper ">
        <div className=" career_container">
          <h1 className="fz-40 color-red">{i18n.t("careers.careerTitle")}</h1>
          <SearchCareer
            searchCareer={searchCareer}
            setQuery={setQuery}
            isLoading={isLoading}
          />
          {isLoading ? (
            <div className="empty-wrapper">
              <LoadingOutlined style={{ fontSize: 30 }} />
            </div>
          ) : (
            <div className="container">
              <Row gutter={[30, 20]}>
                <Col xs={24} sm={24} lg={16}>
                  <ListCareer listCareer={dataCareers} />
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <CareerRecentPost data={careers?.slice(0, 10)} />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Careers;
