import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import blockchain from "../../assets/img/blockchain.jpg";
import blockchain1 from "../../assets/img/Banner_Blockchain.webp";
import { Link } from "react-router-dom";
import i18n from "../../lib/Language";

const Blockchain = () => {
  return (
    <div className="blockchain_container product_item_container">
      <h3>Blockchain</h3>
      <Row gutter={[30, 30]} align="middle">
        <Col xs={24} lg={8}>
          <Link to="/solutions/blockchain">
            <CardBasic
              title="BDOTS"
              imgSrc={blockchain}
              desc={i18n.t("products.productBDOTSDesc")}
            />
          </Link>
        </Col>
        <Col xs={24} lg={8}>
          <Link to="/solutions/blockchain">
            <CardBasic
              title="AI4EDU"
              imgSrc={blockchain1}
              desc={i18n.t("products.productAI4EDUDesc")}
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Blockchain;
