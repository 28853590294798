import React, { useEffect } from "react";
import key_values from "../../assets/img/key_values.webp";
import ContentAbout from "../../components/AboutUs/ContentAbout";
import Facts from "../../components/AboutUs/Facts";
import ManagementTeam from "../../components/AboutUs/ManagementTeam";
import WhyUs from "../../components/AboutUs/WhyUs";
import i18n from "../../lib/Language";
import Award from "../../components/AboutUs/Award";
import Presentation from "../../components/AboutUs/Presentation";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="box-style">
      <div className="container-wrapper ">
        <div className="container about_container">
          <ContentAbout />
          <Facts />
          <div className="key_values">
            <h1 className="fz-40 color-red">
              {i18n.t("aboutUs.ourKeyValues.ourKeyValueTitle")}
            </h1>
            <p>{i18n.t("aboutUs.ourKeyValues.ourKeyValueDesc")}</p>
            <div className="img_values">
              <img src={key_values} alt="" />
            </div>
          </div>
          <ManagementTeam />
          <WhyUs />
          <Award/>
          <Presentation/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
