import { useState } from 'react';
import { useRecoilState } from 'recoil';
import newsApi from '../api/newsApi';
import { newsState } from '../recoil/atom/newsState';

export const useNews = () => {
  const [similarNews, setSimilarNews] = useState([]);
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newss, setNewss] = useRecoilState(newsState);

  const getAllNewss = async () => {
    setIsLoading(true);
    try {
      let res = await newsApi.getAllNewss();
      if (res?.data) {
        setNewss(res?.data?.elements?.sort((a, b) => b.id - a.id)?.filter((item)=>item?.TYPE_ID === 1));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getNews = async (id,) => {
    setIsLoading(true);
    try {
      let res = await newsApi.getNews(id);
      if (res.data) {
        setNews(res.data.elements.news);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getNewsListByNewsId = async (id) => {
    setIsLoading(true);
    try {
      let res = await newsApi.getNewsListByNewsId(id);
      if (res.data) {
        setSimilarNews(res.data?.elements?.newsList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getAllByQuery = async () => {
    setIsLoading(true);
    try {
      let res = await newsApi.getAllByQuery({ TYPE_ID: 1 });
      if (res?.data) {
        setNewss(res?.data?.elements.sort((a, b) => b.id - a.id));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    newss,
    news,
    similarNews,
    getAllNewss,
    getNews,
    getNewsListByNewsId,
    isLoading,
    getAllByQuery
  };
};
