import React, { useEffect } from "react";
import BigDataSolution from "../../../components/Solution/BigData/BigDataSolution";
import BISolution from "../../../components/Solution/BigData/BISolution";
import AISolution from "../../../components/Solution/BigData/AISolution";

const Bigdatas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="box-style">
      <div className="container-wrapper ">
        <div className="container">
          <BigDataSolution />
          <BISolution />
          <AISolution/>
        </div>
      </div>
    </div>
  );
};

export default Bigdatas;
