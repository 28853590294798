import { Col, Image, Row } from "antd";
import React from "react";
import moment from "moment";
import { formatDate, getLocalMoment } from "../../common";
import { Link } from "react-router-dom";
import i18n from "../../lib/Language";
import "moment/locale/vi";
import "moment/locale/ko";
import "moment/locale/ja";
import { languageState } from "../../recoil/atom/languageState";
import { useRecoilValue } from "recoil";

const CareerRecentPost = ({ data }) => {
  const language = useRecoilValue(languageState);
  moment.locale(getLocalMoment(language));
  return (
    <div className="careers_recent_container">
      <div className="careers_recent_container_header">
        <p>{i18n.t("careers.recentPost")}</p>
      </div>
      {data?.map((item, index) => (
        <div className="careers_recent_item" key={index}>
          <Link to={`/careers/${item?.id}`}>
            <Row gutter={[10]}>
              <Col span={8}>
                <Image
                  src={item?.IMAGE}
                  alt=""
                  preview={false}
                  height={80}
                  width="100%"
                  style={{ borderRadius: 8 }}
                />
              </Col>
              <Col
                span={16}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h5 className="careers_recent_item_title">{item?.TITLE}</h5>
                <p className="careers_recent_item_desc">{item?.DESC}</p>
                <span style={{ color: "#727272", fontSize: 12 }}>
                  {moment(item?.CREATED_DATE).format(formatDate.TypeEng)}
                </span>
              </Col>
            </Row>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CareerRecentPost;
