import { Dropdown } from "antd";
import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import { CheckOutlined } from "@ant-design/icons";
import japan from "../../../assets/img/country/japan.png";
import vietnam from "../../../assets/img/country/vietnam.png";
import england from "../../../assets/img/country/england.png";
import { languageState } from "../../../recoil/atom/languageState";
import korea from "../../../assets/img/country/korea_south_icon.png";
import arrow_down from "../../../assets/img/icons/angle_down_black.png";

const ChangeLanguage = () => {
  const [language, setLanguage] = useRecoilState(languageState);
  const onClick = ({ key }) => {
    localStorage.setItem("language", key);
    setLanguage(key);
  };

  const items = [
    {
      key: "vie",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={vietnam} href="Vệt Nam" />
          <span style={{ marginLeft: 10 }}>VN</span>
        </div>
      ),
      icon: (
        <>
          {language === "vie" ? (
            <div style={{ width: 20 }}>
              <CheckOutlined />
            </div>
          ) : (
            <div style={{ width: 20 }}></div>
          )}
        </>
      ),
    },
    {
      key: "en",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={england} href="England" />
          <span style={{ marginLeft: 10 }}>EN</span>
        </div>
      ),
      icon: (
        <>
          {language === "en" ? (
            <div style={{ width: 20 }}>
              <CheckOutlined />
            </div>
          ) : (
            <div style={{ width: 20 }}></div>
          )}
        </>
      ),
    },
    {
      key: "jap",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={japan} href="Japan" />
          <span style={{ marginLeft: 10 }}>JP</span>
        </div>
      ),
      icon: (
        <>
          {language === "jap" ? (
            <div style={{ width: 20 }}>
              <CheckOutlined />
            </div>
          ) : (
            <div style={{ width: 20 }}></div>
          )}
        </>
      ),
    },
    {
      key: "kor",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={korea} href="Korea" />
          <span style={{ marginLeft: 10 }}>KR</span>
        </div>
      ),
      icon: (
        <>
          {language === "kor" ? (
            <div style={{ width: 20 }}>
              <CheckOutlined />
            </div>
          ) : (
            <div style={{ width: 20 }}></div>
          )}
        </>
      ),
    },
  ];

  const languageSelect = useMemo(() => {
    return items?.find((item) => item.key === language);
  }, [language]);

  return (
    <div className="flex-jus-end  ">
      <Dropdown
        trigger={["click"]}
        menu={{
          items,
          onClick,
        }}
        placement="bottomLeft"
        className="checkMenu"
      >
        <div className="change_language_container">
          <a
            onClick={(e) => e.preventDefault()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>{languageSelect?.label}</span> <img src={arrow_down} alt="" />
          </a>
        </div>
      </Dropdown>
    </div>
  );
};

export default ChangeLanguage;
