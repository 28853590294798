import React from "react";
import PageRender from "../PageRender";
import { Route, Switch } from "react-router-dom";
import Apply from "../pages/apply";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={PageRender} />
      <Route exact path="/apply" component={Apply} />
      <Route exact path="/:appName" component={PageRender} />
      <Route exact path="/:appName/:id" component={PageRender} />
      {/* <Route exact path="/:appName/:page/:id" component={PageRender} /> */}
      {/* <Route exact path="/:appName/:page/:slug" component={PageRender} /> */}
      {/* <Route exact path="/:appName/:page/:slug/:id" component={PageRender} /> */}
    </Switch>
  );
};

export default Routes;
