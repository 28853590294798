import React from "react";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import BannerButton from "../../Global/Buttons/BannerButton";
import i18n from "../../../lib/Language";
const contentStyle = {
  height: "300px",
  textAlign: "center",
};

const BannerMobile = () => {
  return (
    <Carousel autoplay speed={1000} dots={false}>
      <div>
        <h3 style={{ ...contentStyle }} className="bg-mobile bg-1">
          <div className="flex-center slider-mobile-wrapper">
            <div className="slider-info-mobile">
              <h1 className="text-center">
                {i18n.t("home.panel.panelNova.panelNovaTitle")}
              </h1>
              <p className="mt-16 text-center">
                {i18n.t("home.panel.panelNova.panelNovaDesc")}
              </p>
              <div style={{ marginTop: 20 }}>
                <Link to="/solutions/blockchain">
                  <BannerButton title={i18n.t("home.panel.panelBtn")} />
                </Link>
              </div>
            </div>
          </div>
        </h3>
      </div>
      <div>
        <h3 style={{ ...contentStyle }} className="bg-mobile bg-2">
          <div className="flex-center slider-mobile-wrapper">
            <div className="slider-info-mobile">
              <h1 className="text-center">
                {i18n.t("home.panel.panelTwell.panelTwellTitle")}
              </h1>
              <p className="mt-16 text-center">
                {i18n.t("home.panel.panelTwell.panelTwellDesc")}
              </p>
              <div style={{ marginTop: 20 }}>
                <Link to="/solutions/twells">
                  <BannerButton title={i18n.t("home.panel.panelBtn")} />
                </Link>
              </div>
            </div>
          </div>
        </h3>
      </div>
      <div>
        <h3 style={{ ...contentStyle }} className="bg-mobile bg-3">
          <div className="flex-center slider-mobile-wrapper">
            <div className="slider-info-mobile">
              <h1 className="text-center">
                {i18n.t("home.panel.panelStem.panelStemTitle")}
              </h1>
              <p className="mt-16 text-center">
                {i18n.t("home.panel.panelStem.panelStemDesc")}
              </p>
              <div style={{ marginTop: 20 }}>
                <a href="http://stemsquare.vn/" target="_blank">
                  <BannerButton title={i18n.t("home.panel.panelBtn")} />
                </a>
              </div>
            </div>
          </div>
        </h3>
      </div>
    </Carousel>
  );
};

export default BannerMobile;
