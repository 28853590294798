import { Button } from "antd";
import React, { useMemo, useState } from "react";
import BannerButton from "../../../components/Global/Buttons/BannerButton";
import PortfolioTab from "../../../components/Home/OutPortfolio";
import { ArrowRightOutlined } from "@ant-design/icons";
import twell from "../../../assets/img/twell.svg";
import blockchain from "../../../assets/img/blockchain.jpg";
import stemsquare from "../../../assets/img/stemsquare.svg";
import blockchain1 from "../../../assets/img/Banner_Blockchain.webp";

import hrm from "../../../assets/img/erp/hrm.webp";
import crm from "../../../assets/img/erp/crm.webp";
import fms from "../../../assets/img/erp/fms.webp";

import i18n from "../../../lib/Language";
import { Link } from "react-router-dom";

const OurPortfolio = () => {
  const data = [
    {
      id: 1,
      TYPE: 2,
      IMAGE: stemsquare,
      TITLE: "STEMSQUARE",
      link: "http://stemsquare.vn/",
      DESC: i18n.t("stem.stemDesc"),
    },
    {
      id: 2,
      TYPE: 3,
      IMAGE: blockchain,
      TITLE: "BLOCKCHAIN",
      link: "/solutions/blockchain",
      DESC: i18n.t("blockchain.blockchainDesc"),
    },
    {
      id: 7,
      TYPE: 4,
      IMAGE: fms,
      TITLE: "FMS",
      link: "/products/fms",
      DESC: i18n.t("products.productFMSTitleSolution"),
    },

    {
      id: 4,
      TYPE: 5,
      IMAGE: twell,
      TITLE: "TWELL",
      link: "/solutions/twells",
      DESC: i18n.t("home.panel.panelTwell.panelTwellDesc"),
    },
    {
      id: 5,
      TYPE: 4,
      IMAGE: hrm,
      TITLE: i18n.t("products.productAbbName"),
      link: "/products/hrm",
      DESC: i18n.t("products.productHrmTitleSolution"),
    },
    {
      id: 6,
      TYPE: 4,
      IMAGE: crm,
      TITLE: i18n.t("products.productCRMAbbName"),
      link: "/products/crm",
      DESC: i18n.t("products.productCRMTitleSolution"),
    },
    {
      id: 3,
      TYPE: 3,
      IMAGE: blockchain1,
      TITLE: "BLOCKCHAIN",
      link: "/solutions/blockchain",
      DESC: i18n.t("blockchain.blockchainDesc"),
    },
  ];
  const [key, setKey] = useState({ key: 1, type: 0 });
  const lists = useMemo(() => {
    if (key.type === 0) {
      return data;
    } else {
      return data?.filter((item) => item.TYPE === key.type);
    }
  }, [key, data]);

  return (
    <div className="container">
      <div className="portfilio-containner flex-column-item-center mt-50">
        <h1 style={{ marginBottom: 20 }} className="fz-40 color-red">
          {i18n.t("home.portfolio.portfolioTitle")}
        </h1>
        <div className="info-solution">
          <p className="text-center">
            {i18n.t("home.portfolio.portfolioDesc")}
          </p>
        </div>
      </div>
      <div className="flex-center button-group-wrapper">
        <div className="button-group">
          <Button
            className={`btn btn-tabs-item ${1 === key.key && "btn-active"}`}
            key={0}
            onClick={() => setKey({ key: 1, type: 0 })}
          >
            {i18n.t("home.portfolio.portfolioBtnAll")}
          </Button>
          <Button
            className={`btn btn-tabs-item ${2 === key.key && "btn-active"}`}
            key={1}
            onClick={() => setKey({ key: 2, type: 2 })}
          >
            Stem Square
          </Button>
          <Button
            className={`btn btn-tabs-item ${3 === key.key && "btn-active"}`}
            key={2}
            onClick={() => setKey({ key: 3, type: 3 })}
          >
            Blockchain
          </Button>
          <Button
            className={`btn btn-tabs-item ${4 === key.key && "btn-active"}`}
            key={2}
            onClick={() => setKey({ key: 4, type: 4 })}
          >
            ERP
          </Button>
          <Button
            className={`btn btn-tabs-item ${5 === key.key && "btn-active"}`}
            key={3}
            onClick={() => setKey({ key: 5, type: 5 })}
          >
            TWELL Smart Home
          </Button>
        </div>
      </div>
      <div className="btn-tabs" style={{ marginTop: 30, marginBottom: 30 }}>
        <PortfolioTab data={lists} />
      </div>
      <div className="flex-jus-center">
        <Link to="/products">
          <BannerButton
            title={i18n.t("home.portfolio.portfolioBtnViewAll")}
            icon={<ArrowRightOutlined />}
          />
        </Link>
      </div>
    </div>
  );
};

export default OurPortfolio;
