import React from "react";
// import { FloatButton } from "antd";
import { useParams } from "react-router-dom";
import Header from "./components/Global/Header";
import Footer from "./components/Global/Footer";

const PageRender = () => {
  const { appName, id } = useParams();
  const checkIdIsNotNumber = isNaN(id);
  const component = () => {
    // edit by thanhdo
    if (!appName) {
      return require(`./pages`).default;
    }

    if (id) {
      if (checkIdIsNotNumber)
        return require(`./pages/${appName}/${id}`).default;
      else return require(`./pages/${appName}/${appName}_detail`).default;
    }

    return require(`./pages/${appName}`).default;

    // if (appName) {
    //   if (id) {
    //     return require(`./pages/${appName}/${appName}_detail`).default;
    //   } else {
    //     return require(`./pages/${appName}`).default;
    //   }
    // } else {
    //   return require(`./pages`).default;
    // }
  };

  return (
    <div className="page-wrapper">
      <Header />
      {React.createElement(component())}
      {/* <FloatButton.BackTop /> */}
      <Footer />
    </div>
  );
};
export default PageRender;
