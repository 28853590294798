import { Card } from "antd";
import React from "react";
import BannerButton from "../Global/Buttons/BannerButton";
import { NavLink } from "react-router-dom";

const CardCareer = ({ imgSrc, desc, nameCareer, addressCareer, careerId }) => {
  return (
    <>
      <Card
        hoverable
        style={{ width: "100%" }}
        cover={<img alt="example" src={imgSrc} />}
      >
        <div className="info_career">
          <div className="name_address flex-between">
            <span className="name_career">{nameCareer}</span>
            {/* <span className="address_career">{addressCareer}</span> */}
          </div>
          <p className="desc_career">{desc}</p>
          <NavLink to={`/careers/${careerId}`}>
            <BannerButton title="Detail" />
          </NavLink>
        </div>
      </Card>
    </>
  );
};

export default CardCareer;
