import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import hrm from "../../assets/img/erp/hrm.webp";
import fms from "../../assets/img/erp/fms.webp";
import crm from "../../assets/img/erp/crm.webp";
import lms from "../../assets/img/erp/lms.webp";
import sns from "../../assets/img/erp/sns.webp";

import { NavLink } from "react-router-dom";
import i18n from "../../lib/Language";

const ERPProduct = () => {
  const arrERP = [
    {
      title: i18n.t("products.productAbbName"),
      imgSrc: hrm,
      desc: i18n.t("products.productHrmTitleSolution"),
      link: "/products/hrm",
    },
    {
      title: i18n.t("products.productLMSAbbName"),
      imgSrc: lms,
      desc: i18n.t("products.productLMSTitleSolution"),
      link: "/products/lms",
    },
    {
      title: i18n.t("products.productCRMAbbName"),
      imgSrc: crm,
      desc: i18n.t("products.productCRMTitleSolution"),
      link: "/products/crm",
    },
    {
      title: i18n.t("products.productFMSAbbName"),
      imgSrc: fms,
      desc: i18n.t("products.productFMSTitleSolution"),
      link: "/products/fms",
    },
    {
      title: i18n.t("products.productSNSAbbName"),
      imgSrc: sns,
      desc: i18n.t("products.productSNSTitleSolution"),
      link: "/products/sns",
    },
  ];
  return (
    <div className="stem_square_container product_item_container">
      <h3>{i18n.t("products.productErp")}</h3>
      <Row gutter={[30, 30]} align="middle">
        {arrERP.map((itemERP) => (
          <Col xs={24} lg={8}>
            <NavLink to={itemERP.link}>
              <CardBasic
                title={itemERP.title}
                imgSrc={itemERP.imgSrc}
                desc={itemERP.desc}
              />
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ERPProduct;
