import { Col, Row } from "antd";
import React from "react";
import CardBasic from "../Global/Cards/CardBasic";
import stem from "../../assets/img/stem.webp";
import i18n from "../../lib/Language";

const StemSquare = () => {
  return (
    <div className="stem_square_container product_item_container">
      <h3>StemSquare</h3>
      <Row gutter={[30, 30]} align="middle">
        <Col xs={24} lg={8}>
          <a href="http://stemsquare.vn/" target="_blank">
            <CardBasic
              title="STEM Robotics"
              imgSrc={stem}
              desc={i18n.t("products.productStemDesc")}
            />
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default StemSquare;
