import React from "react";
import BannerPc from "../../../components/Home/Banner/BannerPc";
import BannerMobile from "../../../components/Home/Banner/BannerMobile";

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-pc">
        <BannerPc />
      </div>
      <div className="slider-mobile">
        <BannerMobile />
      </div>
    </div>
  );
};

export default Banner;
