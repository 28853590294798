import { Image } from "antd";
import React from "react";

const ProductItem = ({ img, content }) => {
  return (
    <div className="product-blockchain">
      <div className="blockchain-img">
        <Image src={img} />
        {/* <img src={img} /> */}
      </div>
      <div style={{ marginTop: 30 }}>
        <p className="text-center">{content}</p>
      </div>
    </div>
  );
};

export default ProductItem;
