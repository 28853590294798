import axiosApiInstance from "../utils/axiosClient";

const newsApi = {
  getAllNewss: () => {
    const path = `/news/getAll`;
    return axiosApiInstance.get(path);
  },
  getNews: (id) => {
    const path = `/news/getNews/${id}`;
    return axiosApiInstance.get(path);
  },
  getNewsListByNewsId: (id) => {
    const path = `/news/getNewsListByNewsId/${id}`;
    return axiosApiInstance.get(path);
  },
  getAllByQuery: (query) => {
    const path = `/news/getAllByQuery`;
    return axiosApiInstance.post(path, query);
  },
};

export default newsApi;
